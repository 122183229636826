import React from 'react'
import classNames from 'classnames'
import { displayNameInitials } from '../../libs'

type AccountAvatarProps = {
  className?: string
  color?: string
  displayName: string
  photoURL?: string
  size?: number
}

const AccountAvatar: React.FC<AccountAvatarProps> = ({
  className,
  color = 'primary',
  displayName,
  photoURL,
  size = 32
}) => {

  const initials = displayNameInitials(displayName)
  const style: React.CSSProperties = {
    width: size,
    height: size,
    borderRadius: size
  }

  const [isPhotoVisible, setPhotoVisibility] = React.useState<boolean>(!!photoURL)

  function handleOnErrorImage () {
    setPhotoVisibility(false)
  }

  return (
    <div className={classNames('account-avatar', `bg-${color}`, className)} title={displayName} style={style}>
      {isPhotoVisible && (
        <img src={photoURL} alt={initials} style={{ width: size }} onError={handleOnErrorImage} />
      )}

      {!isPhotoVisible && (
        <div className={`h-100 w-100 d-flex align-items-center justify-content-center`}>
          <span className="initials">{initials}</span>
        </div>
      )}
    </div>
  )
}

export default AccountAvatar
