import React from 'react'
import { RouteComponentProps, Link } from 'react-router-dom'
import { Button, Card, Form, Label, Input, Alert } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { useMountedState } from 'react-use'
import { useAppTitle } from '../../hooks'
import { withFirebase, InjectedFirebase } from '../Firebase'
import { ROUTES } from '../../constants'

type SignInProps = RouteComponentProps & InjectedFirebase

type SignInAlertType = 'sign_error_general'

// TODO: Move alert / login error to notification
// TODO: Add Loading button? or similar progress
// TODO: Add email & password sign in
// TODO: Change layout e.g. stripe.com
const SignIn: React.FC<SignInProps> = ({
  firebase,
  history
}) => {

  const { t } = useTranslation()
  const isMounted = useMountedState()
  const [isSigningIn, setSigningIn] = React.useState(false)
  const [visibleAlert, setVisibleAlert] = React.useState<SignInAlertType | undefined>(undefined)

  useAppTitle(t('sign_in.page_title'))

  async function handleOnGoogleClick () {
    setVisibleAlert(undefined)
    setSigningIn(true)

    try {
      await firebase.signInWithGoogle()

      firebase.analytics?.logEvent('sign_in', { type: 'google' })
      history.push(ROUTES.DASHBOARD)

    } catch (error) {
      firebase.analytics?.logEvent('sign_in_error', { type: 'google', code: error.code })
      isMounted() && setVisibleAlert('sign_error_general')

    } finally {
      isMounted() && setSigningIn(false)
    }
  }

  function handleAlertDismiss () {
    setVisibleAlert(undefined)
  }

  return (
    <main className="d-flex h-100">
      <div className="sign-in-form">

        <Alert color="danger" isOpen={visibleAlert === 'sign_error_general'} toggle={handleAlertDismiss}>
          {t('sign_in.error.general')}
        </Alert>

        <Card body className="text-center ">
          <h1 className="h3 mb-3 font-weight-normal">
            {t('sign_in.heading')}
          </h1>

          <div className="d-none">
            <Form disabled>
              <Label for="email" className="sr-only">{t('sign_in.email_label')}</Label>
              <Input name="email" type="email" placeholder={t('sign_in.email_placeholder')} />

              <Label for="password" className="sr-only">{t('sign_in.password_label')}</Label>
              <Input name="password" type="password" placeholder={t('sign_in.password_placeholder')} />

              <Button block disabled={isSigningIn} type="submit" color="primary" className="mt-2">
                {t('sign_in.submit_button')}
              </Button>

              <div className="mt-2">
                <small>
                  <span>{t('sign_in.sign_up.description')}</span>
                  <Link to={ROUTES.DASHBOARD}>{t('sign_in.sign_up.action')}</Link>
                </small>
              </div>
            </Form>

            <p className="my-2 text-muted">{t('sign_in.or_text')}</p>
          </div>

          <Button block outline color="primary" disabled={isSigningIn} onClick={handleOnGoogleClick}>
            {t('sign_in.google_button_text')}
          </Button>
        </Card>
      </div>
    </main>
  )
}

export default withFirebase(SignIn)
