import React from 'react'
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom'
import { ROUTES } from '../constants'
import SignIn from './SignIn'
import Dashboard from './Dashboard'

const App: React.FC = () => (
  <React.Suspense fallback={null}>
    <Router>
      <Redirect exact from={ROUTES.LANDING} to={ROUTES.DASHBOARD} />
      <Route path={ROUTES.SIGN_IN} component={SignIn} />
      <Route path={ROUTES.DASHBOARD} component={Dashboard} />
    </Router>
  </React.Suspense>
)

export default App
