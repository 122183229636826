import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAppTitle } from '../../hooks'
import { withAuthorization, AuthCondition } from '../Session'
import PanelLayout from '../../layouts/PanelLayout'

type DashboardProps = RouteComponentProps

// TODO: Find out why Dashboard render twice
const Dashboard: React.FC<DashboardProps> = () => {

  const { t } = useTranslation()

  useAppTitle(t(`dashboard.page-title`))

  return (
    <PanelLayout>
      <div>Hello world!</div>
    </PanelLayout>
  )
}

const condition: AuthCondition = user => !!user

export default withAuthorization(condition)(Dashboard)
