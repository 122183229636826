import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import Firebase from 'firebase'
import { InjectedFirebase, withFirebase } from '../Firebase'
import { ROUTES } from '../../constants'
import { useMountedState } from 'react-use'

export type AuthCondition = (user: Firebase.User) => boolean

export const withAuthorization = (condition: AuthCondition) => (
  Component: React.ComponentType<RouteComponentProps>
) => withFirebase(({
  firebase,
  ...props
}: RouteComponentProps & InjectedFirebase) => {

  const isMounted = useMountedState()
  const [isWaiting, setWaiting] = React.useState(true)

  React.useEffect(() => {
    const listener = firebase.auth.onAuthStateChanged(user => {
      if (!isMounted()) {
        return
      }

      setWaiting(false)

      if (!user || !condition(user)) {
        props.history.push(ROUTES.SIGN_IN)
      }
    })

    return () => listener()
  })

  // TODO: Add proper progress?
  if (isWaiting) {
    return null
  }

  return (
    <Component {...props as RouteComponentProps} />
  )
})

export default withAuthorization
