import * as firebase from 'firebase/app'
// tslint:disable: no-import-side-effect
import 'firebase/auth'
import 'firebase/analytics'
// tslint:enable: no-import-side-effect

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  appId: process.env.REACT_APP_APP_ID,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET
}

class Firebase {

  public auth: firebase.auth.Auth
  public analytics: firebase.analytics.Analytics | undefined

  private googleProvider = new firebase.auth.GoogleAuthProvider()

  constructor () {
    firebase.initializeApp(config)

    this.auth = firebase.auth()

    if (process.env.NODE_ENV !== 'development') {
      this.analytics = firebase.analytics()
    }
  }

  get user (): firebase.User | undefined {
    return this.auth.currentUser || undefined
  }

  signInWithGoogle () {
    return this.auth.signInWithPopup(this.googleProvider)
  }

  signOut () {
    return this.auth.signOut()
  }
}

export default Firebase
