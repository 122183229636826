import React from 'react'
import Firebase from './firebase'
import FirebaseContext from './firebase.context'

export interface InjectedFirebase {
  firebase: Firebase
}

export function withFirebase <P extends InjectedFirebase> (Component: React.ComponentType<P>) {
  return (props: Omit<P, keyof InjectedFirebase>) => (
    <FirebaseContext.Consumer>
      { state => <Component {...props as P} firebase={state} /> }
    </FirebaseContext.Consumer>
  )
}
