export function displayNameInitials (displayName: string): string {
  const names = displayName.split(' ')
  const namesCount = names.length
  let initials = names[0].substring(0, 1).toUpperCase()

  if (namesCount > 1) {
    initials += names[namesCount - 1].substring(0, 1).toUpperCase()
  }

  return initials
}
