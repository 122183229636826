import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Row, Col } from 'reactstrap'
import { withFirebase, InjectedFirebase } from '../components/Firebase'
import NavigationBar from '../components/NavigationBar'

// TODO: Should layout has firebase provider?
const PanelLayout: React.FC<InjectedFirebase> = ({
  children,
  firebase
}) => {

  const { t } = useTranslation()

  async function handleSignOutClick () {
    await firebase.signOut()
  }

  return (
    <>
      <header className="mb-3">
        <Container>
          <Row>
            <Col md="12" xl="10">
            <NavigationBar
              displayName={firebase.user?.displayName || ''}
              email={firebase.user?.email || undefined}
              photoURL={firebase.user?.photoURL || undefined }
              title={t('dashboard.page-title')} onSignOut={handleSignOutClick}
            />
            </Col>
          </Row>
        </Container>
      </header>

      <main>
        <Container>
          <Row>
            <Col md="12" xl="10">{children}</Col>
          </Row>
        </Container>
      </main>
    </>
  )
}

export default withFirebase(PanelLayout)
