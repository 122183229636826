import React from 'react'
import ReactDOM from 'react-dom'
import './assets/sass/bootstrap.scss'
import App from './components/App'
import Firebase, { FirebaseContext } from './components/Firebase'
import * as serviceWorker from './serviceWorker'
// tslint:disable: no-import-side-effect
import './i18n'
// tslint:enable: no-import-side-effect

const firebase = new Firebase()

ReactDOM.render(
  <React.StrictMode>
    <FirebaseContext.Provider value={firebase}>
      <App />
    </FirebaseContext.Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
