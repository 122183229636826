import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import AccountAvatar from '../AccountAvatar'
import { DropdownToggle, DropdownMenu, DropdownItem, Dropdown } from 'reactstrap'

type NavigationBarProps = {
  className?: string
  displayName: string
  email?: string
  photoURL?: string
  title: string
  onSignOut: () => void
}

const NavigationBar: React.FC<NavigationBarProps> = ({
  className,
  displayName,
  email,
  photoURL,
  title,
  onSignOut
}) => {

  const { t } = useTranslation()
  const [isDropdownOpen, setDropdownOpen] = React.useState(false)

  function toggleDropdown () {
    setDropdownOpen(prevState => !prevState)
  }

  return (
    <div className={classNames('navigation-bar', className)}>
      <h5 className="display-5 m-0">
        {title}
      </h5>

      <div className="d-flex align-items-center">
        <Dropdown isOpen={isDropdownOpen} toggle={toggleDropdown}>
          <DropdownToggle color="link" tag="div" className="profile-toggle">
            <AccountAvatar displayName={displayName} photoURL={photoURL} size={38} />
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem header>
              <p className="account-display-name mb-0">{displayName}</p>
              {email && <p className="account-email mb-0">{email}</p>}
            </DropdownItem>
            <DropdownItem divider />

            <DropdownItem onClick={onSignOut}>
              {t('navigation-bar.sign-out-button')}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
  )
}

export default NavigationBar
